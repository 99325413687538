import {cva} from 'class-variance-authority';

import type {VariantProps} from 'class-variance-authority';

export const progressBarStyles = cva('rounded-full relative overflow-hidden pointer-events-none border', {
	variants: {
		color: {
			neutral: 'bg-mauveA3 border-mauve5',
			primary: 'bg-violetA3 border-violet5',
			secondary: 'bg-plumA3 border-plum5',
			success: 'bg-greenA3 border-green5',
			warning: 'bg-yellowA3 border-yellow5',
			danger: 'bg-redA3 border-red5',
			info: 'bg-blueA3 border-blue5',
		},
		size: {sm: 'h-1', md: 'h-2', lg: 'h-3'},
	},
	defaultVariants: {size: 'md', color: 'neutral'},
});

export type ProgressBarVariantProps = VariantProps<typeof progressBarStyles>;

export const progressBarIndicatorStyles = cva('h-full w-full transition-transform duration-500 ease-in-out', {
	variants: {
		color: {
			neutral: 'bg-mauve9',
			primary: 'bg-violet9',
			secondary: 'bg-plum9',
			success: 'bg-green9',
			warning: 'bg-yellow9',
			danger: 'bg-red9',
			info: 'bg-blue9',
		},
		animated: {true: 'animate-pulse', false: ''},
	},
	defaultVariants: {color: 'neutral', animated: false},
});

export type ProgressBarIndicatorVariantProps = VariantProps<typeof progressBarIndicatorStyles>;
