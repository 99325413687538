import {create} from 'zustand';

export type ColorModeStoreData = {
	colorMode?: 'dark' | 'light';
};

export type ColorModeStoreState = ColorModeStoreData & {
	setColorMode: (colorMode: ColorModeStoreData['colorMode']) => void;
};

export const useColorModeStore = create<ColorModeStoreState>(set => ({
	colorMode: undefined,
	setColorMode: colorMode => set({colorMode}),
}));
