'use client';

import {createContext, useState} from 'react';
import {createBrowserClient} from '~/shared/services/supabase/browser';

import type {Database} from '@job-ish/database/types/supabase';
import type {SupabaseClient, User} from '@supabase/supabase-js';
import type {PropsWithChildren} from 'react';

export const SupabaseContext = createContext<{
	supabase: SupabaseClient<Database>;
	user: User | null;
}>({supabase: null!, user: null!});

export const SupabaseProvider = ({children, user}: PropsWithChildren<{user: User | null}>) => {
	const [supabase] = useState(createBrowserClient());

	return <SupabaseContext.Provider value={{supabase, user}}>{children}</SupabaseContext.Provider>;
};
