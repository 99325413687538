import {forwardRef} from 'react';
import * as ProgressPrimitive from '@radix-ui/react-progress';
import {twMerge} from 'tailwind-merge';

import {progressBarIndicatorStyles, progressBarStyles} from './styles';
import type {ProgressBarIndicatorVariantProps, ProgressBarVariantProps} from './styles';

export type ProgressBarProps = Omit<ProgressPrimitive.ProgressProps, 'title'> &
	ProgressBarVariantProps &
	ProgressBarIndicatorVariantProps;
const ProgressBar = forwardRef<HTMLDivElement, ProgressBarProps>(
	({animated, color, size, className, value, ...props}, forwardedRef) => (
		<ProgressPrimitive.Root
			{...props}
			aria-label={props['aria-label'] || 'Progress bar'}
			className={twMerge(progressBarStyles({color, size}), className)}
			ref={forwardedRef}
			value={value}
		>
			<ProgressPrimitive.Indicator
				className={twMerge(progressBarIndicatorStyles({color, animated}), className)}
				ref={forwardedRef}
				style={{transform: `translateX(-${100 - (value ?? 0)}%)`}}
			/>
		</ProgressPrimitive.Root>
	),
);

ProgressBar.displayName = 'ProgressBar';

export default ProgressBar;

export * from './styles';
