'use client';

import {startTransition, useEffect} from 'react';
import {useSupabase} from '~/shared/hooks/use-supabase';
import {useRouter} from 'next/navigation';

type SupabaseListenerProps = {
	serverAccessToken?: string;
};

export const SupabaseListener = ({serverAccessToken}: SupabaseListenerProps) => {
	const {supabase} = useSupabase();
	const router = useRouter();

	useEffect(() => {
		const {
			data: {subscription},
		} = supabase.auth.onAuthStateChange((_, session) => {
			if (session?.access_token !== serverAccessToken) {
				startTransition(() => {
					router.refresh();
				});
			}
		});

		return () => {
			subscription.unsubscribe();
		};
	}, [serverAccessToken, router, supabase]);

	return null;
};
