'use client';

import {useCookie} from 'react-use';
import {CONSENT_COOKIE} from '~/shared/constants/cookies';
import {Button, Toast} from '@job-ish/ui/components';
import {IconCookie} from '@tabler/icons-react';
import NextLink from 'next/link';

export const CookieConsent = () => {
	const [consentGiven, setConsentGiven] = useCookie(CONSENT_COOKIE);

	return (
		<Toast.Provider swipeDirection="right">
			<Toast
				accent="info"
				accentPosition="left"
				description="We use essential cookies, such as those for color modes, to enhance your experience on our website. By continuing to use our site, you consent to the use of these functional cookies."
				draggable={false}
				open={!consentGiven}
				title={
					<div className="flex items-center gap-1">
						<IconCookie className="h-6 w-6 stroke-mauve11" />
						<div>
							<span className="font-bold">job-ish</span> uses cookies
						</div>
					</div>
				}
			>
				<div className="flex w-full items-center justify-end gap-2">
					<Toast.Action altText="Learn more about our use of cookies" asChild>
						<Button as={NextLink} href="/privacy-policy" intent="ghost" size="sm">
							Learn more
						</Button>
					</Toast.Action>
					<Toast.Action altText="Agree to use of cookies" asChild>
						<Button color="info" onPress={() => setConsentGiven('true')} size="sm">
							Agree
						</Button>
					</Toast.Action>
				</div>
			</Toast>
		</Toast.Provider>
	);
};
