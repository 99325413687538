import {cva} from 'class-variance-authority';

import type {VariantProps} from 'class-variance-authority';

export const toastStyles = cva(
	[
		'relative outline-none focus-visible:outline-1 focus-visible:outline-mauve8 w-96 max-w-full min-h-fit transition-all z-[1000]',
		'data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-80 data-[state=closed]:slide-out-to-right-full data-[state=open]:slide-in-from-bottom-full data-[state=open]:sm:slide-in-from-bottom-full',
	],
	{
		variants: {
			draggable: {
				true: 'data-[swipe=cancel]:translate-x-0 data-[swipe=end]:translate-x-[var(--radix-toast-swipe-end-x)] data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=move]:transition-none data-[swipe=end]:animate-out',
			},
		},
		defaultVariants: {
			draggable: true,
		},
	},
);

export const toastViewportStyles = cva(
	'fixed bottom-0 right-0 max-w-full p-4 outline-none [&>*]:my-2 z-[1000] flex-col-reverse',
);

export type ToastVariantProps = VariantProps<typeof toastStyles>;
export type ToastViewportVariantProps = VariantProps<typeof toastViewportStyles>;
