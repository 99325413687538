import {useCallback, useId, useState} from 'react';
import {mergeProps} from '@react-aria/utils';
import omit from 'lodash.omit';
import {create} from 'zustand';

import type {ToastProps} from '../components/toast';

export type ToastStoreState = {
	toasts: Record<string, ToastProps | undefined>;
	addToast: (toast: ToastProps) => void;
	removeToast: (id: string) => void;
};

export const useToastStore = create<ToastStoreState>(set => ({
	toasts: {},
	addToast: toast => toast.id && set(state => ({toasts: {...state.toasts, [toast.id!]: toast}})),
	removeToast: id => {
		set(
			state =>
				({
					toasts: {...state.toasts, [id]: {...state.toasts[id], open: false}},
				}) as ToastStoreState,
		);
		setTimeout(() => set(state => ({...state, toasts: omit(state.toasts, id)})), 150);
	},
}));

export type UseToastParams = Partial<Omit<ToastProps, 'open'>>;

type RequiredToastProps = Pick<ToastProps, 'title' | 'description'>;

export const useToast = <T extends UseToastParams>({onDurationElapsed, onOpenChange, id, ...props}: T) => {
	const defaultId = useId();
	const toastId = id || defaultId;
	const [open, setOpen] = useState(false);
	const {addToast, removeToast} = useToastStore();

	const show = useCallback(
		(
			showProps?: Omit<Partial<ToastProps>, 'id' | 'open'> &
				Omit<RequiredToastProps, keyof T & keyof RequiredToastProps>,
		) => {
			addToast({
				...(mergeProps(props, showProps) as ToastProps),
				open: !open,
				onOpenChange: open => {
					if (!open) removeToast(toastId);
					setOpen(open);
					onOpenChange?.(open);
				},
				onDurationElapsed: () => {
					onDurationElapsed?.();
					showProps?.onDurationElapsed?.();
					removeToast(toastId);
				},
				id: toastId,
			});
		},
		[addToast, onDurationElapsed, onOpenChange, open, props, removeToast, toastId],
	);

	const remove = useCallback(() => removeToast(toastId), [removeToast, toastId]);

	return {show, remove};
};
