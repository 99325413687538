'use client';

import {useEffect} from 'react';
import {useColorModeStore} from '~/shared/stores/use-color-mode-store';
import {useColorMode} from '@job-ish/ui/hooks';

type ColorModeProps = {
	initialValue?: 'dark' | 'light' | 'system';
	cookieName: string;
};

export const ColorMode = ({initialValue, cookieName}: ColorModeProps) => {
	const colorMode = useColorMode({initialValue, cookieName});
	const {setColorMode} = useColorModeStore();

	useEffect(() => {
		setColorMode(colorMode);
	}, [colorMode, setColorMode]);

	return null;
};
